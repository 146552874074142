import React from 'react';
import styled from 'styled-components';

import { breakpoints } from '../theme/mediaQueries';

const PageFooter = ({ children, primary }) => (
  <StyledPageFooter as="footer" primary={primary}>
    {children}
  </StyledPageFooter>
);

const StyledPageFooter = styled.div`
  background-color: ${(props) => (props.primary ? props.theme.colors.primary : 'transparent')};
  padding: 2rem 0;
  display: flex;
  justify-content: center;

  @media (min-width: ${breakpoints.md}px) {
    padding: 3rem 0;
  }
  @media (min-width: ${breakpoints.lg}px) {
    padding: 4rem 0;
  }
`;

export default PageFooter;
