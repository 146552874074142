import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ title, description, image: metaImage, imageAlt, lang, path, meta, pageType }) {
  const data = useStaticQuery(graphql`
    {
      site: sanitySiteSettings {
        siteTitle
        siteUrl
        siteAuthor
        shareImage {
          asset {
            url
            fixed {
              src
              height
              width
            }
          }
        }
      }
    }
  `);

  const metaDescription = description;
  const defaultTitle = data.site.siteTitle;
  const canonical = path ? `${data.site.siteUrl}${path}` : null;

  return (
    <Helmet
      htmlAttributes={{
        lang: lang,
      }}
      title={defaultTitle}
      titleTemplate={defaultTitle && title ? `${defaultTitle} | ${title}` : defaultTitle}
      link={
        canonical
          ? [
              {
                rel: 'canonical',
                href: canonical,
              },
            ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        // {
        //   name: 'keywords',
        //   content: keywords ? keywords.join(', ') : data.site.keywords[lang].join(', '),
        // },
        {
          property: `og:title`,
          content: defaultTitle && title ? `${defaultTitle} | ${title}` : defaultTitle,
        },
        {
          property: `og:site_name`,
          content: defaultTitle,
        },
        {
          property: `og:url`,
          content: data.site.siteUrl + path,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: pageType,
        },
        {
          name: `twitter:title`,
          content: defaultTitle && title ? `${defaultTitle} | ${title}` : defaultTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat([
          {
            property: 'og:image',
            content: metaImage ? metaImage.src : data.site.shareImage.asset.fixed.src,
          },
          // {
          //   property: 'og:image:width',
          //   content: metaImage ? metaImage.width : data.site.shareImage.asset.fixed.src.width,
          // },
          // {
          //   property: 'og:image:height',
          //   content: metaImage ? metaImage.height : data.site.shareImage.asset.fixed.height,
          // },
          {
            name: `twitter:image`,
            content: metaImage ? metaImage.src : data.site.shareImage.asset.fixed.src,
          },
          {
            name: `twitter:image:alt`,
            content: imageAlt ? imageAlt : defaultTitle,
          },
          {
            name: 'twitter:card',
            content: 'summary_large_image',
          },
        ])
        .concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
